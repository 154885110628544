.use-top {
  padding-top: 2.3rem !important;
}
.form-element {
  border: 1px solid #b223cc;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 10px 0;
  font-family: "Courier", serif;

  p {
    padding: 0;
    margin: 0;
  }
}

.card-last-change {
  text-transform: none;
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move !important;
}

.widget-hover {
  background-color: transparent;

  &:hover {
    background-color: #e0f3ff;
  }
}

.user-no-photo {
  border-color: darkgrey;
  border-style: solid;
  border-width: 1px;
}

.table-of-content-card {
  border-color: #e7e7e7 !important;
}

.tox-fullscreen {
  .app-sidebar {
    z-index: 9 !important;
  }

  .app-header {
    z-index: 8 !important;
  }
}
