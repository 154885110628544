@import '../variables';
@import '../mixins';

$rise-amount: 30px;

@keyframes ball-pulse-rise-even {
  0% {
    transform: scale(1.1);
  }
  25% {
    transform: translateY(-$rise-amount);
  }
  50% {
    transform: scale(0.4);
  }
  75% {
    transform: translateY($rise-amount);
  }
  100% {
    transform: translateY(0);
    transform: scale(1.0);
  }
}

@keyframes ball-pulse-rise-odd {
  0% {
    transform: scale(0.4);
  }
  25% {
    transform: translateY($rise-amount);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: translateY(-$rise-amount);
  }
  100% {
    transform: translateY(0);
    transform: scale(0.75);
  }
}

.ball-pulse-rise {

  > div {
    @include balls();
    @include global-animation();

    display: inline-block;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.15,.46,.9,.6);
    animation-iteration-count: infinite;
    animation-delay: 0;

    &:nth-child(2n) {
      animation-name: ball-pulse-rise-even;
    }

    &:nth-child(2n-1) {
      animation-name: ball-pulse-rise-odd;
    }
  }
}
