.user-photo-entity {
  display: flex;
  flex-direction: column;
  border: 1px solid #e9ecef;

  &__no-image {
    min-height: 180px;
    margin: 1px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    background-color: #f6f6f6;
    cursor: pointer;

    .image-choice {
      color: #d3d3d3;
      text-align: center;
    }

    &:hover {
      .image-choice {
        color: #bfbfbf;
      }
    }
  }

  &__image {
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}
