@import '../variables';
@import '../mixins';

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.6);
  }
  100% {
   transform: rotate(360deg) scale(1);
 }
}

@keyframes scale {
  30% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(1);
  }
}

.ball-clip-rotate-pulse {
  position: relative;
  transform: translateY(-15px);

  > div {
    @include global-animation();

    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;

    &:first-child {
      background: $primary-color;
      height: 16px;
      width: 16px;
      top: 7px;
      left: -7px;
      animation: scale 1s 0s cubic-bezier(.09,.57,.49,.9) infinite;
    }

    &:last-child {
      position: absolute;
      border: 2px solid $primary-color;
      width: 30px;
      height: 30px;  
      left: -16px;
      top: -2px;
      background: transparent;
      border: 2px solid;
      border-color: $primary-color transparent $primary-color transparent;
      animation: rotate 1s 0s cubic-bezier(.09,.57,.49,.9) infinite;
      animation-duration: 1s;
    }
  }
}
