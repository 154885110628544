@if $use-lightSpeedIn == true {

	@-webkit-keyframes lightSpeedIn {
		0% { -webkit-transform: translateX(100%) skewX(-$base-degrees); opacity: 0; }
		60% { -webkit-transform: translateX(-20%) skewX($base-degrees); opacity: 1; }
		80% { -webkit-transform: translateX(0%) skewX(-$base-degrees / 2); opacity: 1; }
		100% { -webkit-transform: translateX(0%) skewX(0deg); opacity: 1; }
	}

	@keyframes lightSpeedIn {
		0% { transform: translateX(100%) skewX(-$base-degrees); opacity: 0; }
		60% { transform: translateX(-20%) skewX($base-degrees); opacity: 1; }
		80% { transform: translateX(0%) skewX(-$base-degrees / 2); opacity: 1; }
		100% { transform: translateX(0%) skewX(0deg); opacity: 1; }
	}

	.lightSpeedIn {
		@include animate-prefixer(animation-name, lightSpeedIn);
		@include animate-prefixer(animation-timing-function, $base-timing-function-out);
	}

}
