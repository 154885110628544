.gallery-modal {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  .modal-body {
    padding: 0;

    .row {
      min-height: 100%;
      height: 100%;
    }
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;

    .app-page-title {
      margin: 0;
      padding: 20px;
    }

    .modal-body {
      .left-column {
        height: 100%;
        min-height: 100%;
        background: #f8f9fa;
        border-right: 1px solid #e9ecef;
        padding-right: 0;
        display: flex;
        flex-direction: column;

        .box {
          display: flex;
          flex-direction: column;
        }

        .folder-list {
          flex: 1;
        }

        .loading {
          justify-content: center;
          height: 100%;

          .loader-wrapper {
            width: 100%;
          }
        }

        .im-upload-preview {
          margin-left: 16px;
          margin-right: 16px;
          height: 300px;
          overflow: hidden;
        }
      }

      @media (max-width: 576px) {
        .left-column {
          padding-right: 15px;

          .folder-list {
            max-height: 150px;
          }
        }
      }

      .right-column {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .box {
          display: flex;
          flex-direction: column;
        }

        .loading {
          justify-content: center;
          height: 100%;

          .loader-wrapper {
            width: 100%;
          }
        }

        .image-list {
          flex: 1;

          picture {
            margin: 10px;
            display: block;
            float: left;

            img {
              width: 150px;
              height: 100px;
              padding: 3px;
              background: #fff;
              border: 1px solid #b9b9b9;
              cursor: pointer;
            }
          }

          .load-more {
            clear: both;
            padding-top: 40px;
            padding-bottom: 150px;
          }
        }

        .cropper-image-container {
          display: flex;
          flex-direction: column;
          flex: 1;

          img {
            max-height: calc(100vh - 130px);
          }
        }

        .im-dropzone {
          flex: 1;
          justify-content: center;
          padding-right: 16px;

          .dropzone-wrapper-lg {
            height: 70%;
          }
        }

        .no-images {
          text-align: center;
          font-weight: bold;
          font-size: 2rem;
          text-transform: uppercase;
          color: #b3b3b3;
          margin-top: 200px;
        }

        .active-image {
          img {
            background-color: #f7b924 !important;
          }
        }
      }

      @media (max-width: 576px) {
        .right-column {
          .image-list {
            min-height: 200px;
          }
        }
      }
    }
  }
}
