.data-table {
  margin: 0 15px;
  width: 100%;

  &__table {
    width: 100%;

    thead {
      border-bottom: 1px solid #cccccc;

      tr {
        th {
          background-color: #f7f7f7;
          padding: 10px 0 10px 10px;

          &:hover {
            cursor: pointer;
          }

          &.column_not_sortable {
            cursor: default;
          }

          i {
            position: absolute;
            font-size: 22px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #cccccc;

        &:nth-child(odd) {
          background-color: #ffffff;
        }

        &:nth-child(even) {
          background-color: #f7f7f7
        }

        td {
          padding: 10px 0 10px 10px;
        }
      }
    }
  }
}
