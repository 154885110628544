@import "../../../themes/layout-variables";

.error-multi-select {
  border: 1px solid $red;
  border-radius: 5px;

  & > div {
    border: none;
  }
}

.typeahead-error {
  border: 1px solid $red;
  border-radius: 5px;

  input {
    border: 0;
  }
}
